import './App.css';
import { useRive } from '@rive-app/react-canvas';
import riveFile from './logo.riv';
import welcomeRiveFile from './welcome.riv';
import appStoreButton from './app-store.svg';
import googlePlayButton from './google-play.png';
import React, { useState } from 'react';

function App() {
  const { RiveComponent: ButtonRive } = useRive({
    src: riveFile,
    autoplay: true,
    stateMachines: ["State Machine 1"]
  });

  const { RiveComponent: WelcomeRiveComponent } = useRive({
    src: welcomeRiveFile,
    autoplay: true,
    artboard: "logo"
  });
  
  const xHandle = {
    0: '@chunkodotuk',
    1: '@a_sidenko',
    2: '@krickonaut',
    3: '@chunkodotuk',
    4: '@linuxLIN',
    5: '@krickonaut',
    6: '@narawastu',
    7: '@iryna_soliar',
    8: '@iryna_soliar'
  };

  const images = Array.from({ length: 9 }, (_, i) => `/images/${i + 1}.jpeg`);

  const isDevelopment = process.env.NODE_ENV === 'development';

const getImageUrl = (path) => {
  if (isDevelopment) {
    return path; // Use original path in development
  }
  return `/.netlify/images?url=${path}`; // Use Netlify CDN in production
};

const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <div className="App" style={{
      backgroundColor: 'black',
      height: '100vh',
      width: '100vw',
      margin: 'auto',
      overflow: 'hidden'
    }}>
      <header className="App-header" style={{ backgroundColor: 'black', height: '100%' }}>
        <WelcomeRiveComponent style={{
          height: '40px',
          position: 'fixed',
          top: '10px'
        }} />

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '100px',
          bottom: '100px',
          alignItems: 'center',
          gap: '10px'
        }}>
          <img 
              src={isDevelopment ? images[currentImageIndex] : getImageUrl(images[currentImageIndex])}
              loading="lazy"
              alt="ZeroCam sample"
              style={{
                width: 'auto',
                height: '60vh',
                aspectRatio: '2/3',
                objectFit: 'cover',
                borderRadius: '16px',
                overflow: 'hidden',
                maxWidth: '90%',
              }}
              decoding="async"
            />

          <a 
            href={`https://x.com/${xHandle[currentImageIndex]}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ 
              textDecoration: 'none',
              width: '70%',  // Match the max-width of the image
              alignSelf: 'center'
            }}
          >
            <div style={{ 
              fontSize: '10px',
              color: 'white', 
              textAlign: 'right',
              width: '100%',  // Take full width of parent
            }}>
              {xHandle[currentImageIndex]}
            </div>
          </a>

          <ButtonRive style={{
            zIndex: 0,
            height: '100px',
            width: '60%',
            borderRadius: '100px',
            overflow: 'hidden',
          }} onMouseUp={() => {
            setCurrentImageIndex((prev) => (prev + 1) % images.length);
          }} onTouchEnd={(e) => {
            e.preventDefault(); // Prevent any default touch behavior
            setCurrentImageIndex((prev) => (prev + 1) % images.length);
        }} />
        </div>

        <div style={{
          display: 'flex',
          maxWidth: '100%',
          marginLeft: '10px',
          marginRight: '10px',
          gap: '10px',
          position: 'fixed',
          bottom: '10px'
        }}>
          <a href="https://apps.apple.com/app/id6483933438">
            <img src={appStoreButton} alt="Download on App Store" style={{
              maxHeight: '40px',
              maxWidth: '100%'
            }} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zerocam.app">
            <img src={googlePlayButton} alt="Get it on Google Play" style={{
              maxHeight: '40px',
              maxWidth: '100%'
            }} />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
